<template>
  <div>
    <div v-if="!headers.length">Нет данных<slot></slot></div>
    <template v-else>
      <div class="list_toolbar" v-show="isShowBtnRefresh" :style="styleContainerBtnRefresh">
        <el-button
          title="Обновить"
          type="text"
          icon="el-icon-refresh-right"
          class="size" @click="loadData"
          :loading="loading"
          :style="styleBtnRefresh"></el-button>
      </div>
      <table :class="CSSClasses" :style="CSS" v-loading="loading" class="table-fixed-head">
        <thead>
          <tr>
            <th :style="styleHeader(CSSHeaderTable, item.cssHeader)" v-for="(item, index) in headers" :key="index">{{item.text}}</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="(record, index) in data" :key="index"
              :style="record.isNewRows ? styleObject : null"
              :class="[{'active': record === activeRecord}, colorRows(record)]">
              <td v-for="(item, index) in headers" :key="index">
                <component
                  :is="item.type"
                  :css="item.css"
                  :scopeRow="record"
                  @click.native="activeRecord = record; onClick($event, index, item, record)"
                  :value="record[item.value]"
                  :fixedNumber="item.fixedNumber"
                  :open-card="item.card || item.requestRegistry || {}"
                  :htmlTemplate="item.htmlTemplate"
                  :extended="item.extended"
                  :action="item.action"
                ></component>
              </td>
            </tr>
        </tbody>
        <slot></slot>
     </table>
    </template>
  </div>

</template>

<script>
import stringField from '@/components/Registry/Columns/string_field.vue'
import booleanField from '@/components/Registry/Columns/boolean_field'
import dateField from '@/components/Registry/Columns/date_field'
import datetimeField from '@/components/Registry/Columns/datetime_field'
import floatField from '@/components/Registry/Columns/float_field'
import integerField from '@/components/Registry/Columns/integer_field'
import textField from '@/components/Registry/Columns/text_field'
import xrefField from '@/components/Registry/Columns/xref_field'
import xrefMultiField from '@/components/Registry/Columns/xref_multi_field'
import xrefOuterField from '@/components/Registry/Columns/xref_outer_field'
import fileField from '@/components/Registry/Columns/file_field'
import timeField from '@/components/Registry/Columns/time_field'
import indicatorField from '@/components/Registry/Columns/indicator_field'
import progressField from '@/components/Registry/Columns/progress_field'
import monthField from '@/components/Registry/Columns/month_field'
import htmlField from '@/components/Registry/Columns/html_field.vue'

import mixin from '../mixins'
import clickTableMixin from '@/components/Registry/Columns/click_table_mixin'
import openCardMixin from '@/components/InterfaceEditor/components/openCard_mixin'
import Dashboard from '@/components/Dashboard'
// utils
import RowClassRulesBuilder from '@/core/infrastructure/components/Grid/infrastructure/service/RowClassRules'
import FilterBuilder, { EComponentTypes } from '../utils'
import ActionExecutor from '@/core/infrastructure/service/ActionExecutor'
import { deepCompare } from '@/helpers'
import refreshComponentsMixin from '@/components/InterfaceEditor/components/refreshComponentsMixin'

export default {
  name: 'a-list',
  components: {
    stringField,
    booleanField,
    dateField,
    datetimeField,
    floatField,
    integerField,
    textField,
    xrefField,
    xrefMultiField,
    xrefOuterField,
    fileField,
    timeField,
    indicatorField,
    progressField,
    monthField,
    htmlField,
    Dashboard,
    ActionExecutor
  },
  inject: {
    openRegistryCard: {
      default: () => {}
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    },
    cancelChanges: {
      default: () => {}
    },
    isEditor: {
      default: () => false
    },
    getComponents: {
      default: () => () => []
    }
  },
  mixins: [mixin, clickTableMixin, openCardMixin, refreshComponentsMixin],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    keyField: {
      type: String,
      description: 'key_field'
    },
    CSSHeaderTable: {
      type: String,
      description: 'css_header_table'
    },
    isShowBtnRefresh: {
      type: Boolean,
      description: 'show_refresh_btn',
      default: false
    },
    styleContainerBtnRefresh: {
      type: String,
      description: 'css_style_toolbar',
      default: 'background: white;'
    },
    styleBtnRefresh: {
      type: String,
      description: 'css_style_refresh_btn',
      default: 'font-size: 18px;'
    },
    rowClassRules: {
      type: Array,
      default: function () {
        return []
      },
      editor: 'RowClassRules'
    },
    autoRefresh: {
      type: Object,
      editor: 'AutoRefresh',
      default: function () {
        return {}
      }
    },
    // source: {
    //   type: Object,
    //   editor: 'Source',
    //   default: () => {
    //     return {
    //       type: null,
    //       entityId: null
    //     }
    //   }
    // },
    list: {
      type: Object,
      editor: 'List',
      default: () => {
        return {
          type: null,
          extendObjectId: null,
          requestsId: null
        }
      }
    },
    headers: {
      type: Array,
      editor: 'RegistryHeaders',
      description: 'columns',
      default: () => []
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    }
  },
  data () {
    return {
      loading: false,
      data: [],
      activeRecord: undefined,
      countFilters: 0,
      isFirstInitFilter: true,
      rowClassRulesData: null,
      dataWarehouseCount: 0,
      componentDestroyed: false,
      styleObject: {},
      saveFilters: []
    }
  },
  computed: {
    dataFilters () {
      /* if (this.list.type === 'requests') {
        let filtersRequests = []
        if (this.filters) {
          this.filters.forEach((item) => {
            let type = `=`
            // if (item.isXref) {
            //   type = `eqx`
            // }
            if (!item.type || item.type === 'field') {
              if (this.getModel()[item.attribute] && item.alias) {
                filtersRequests.push(`${item.alias}${type}${this.getModel()[item.attribute]}`)
              }
            } else if (item.type === 'constant' && item.alias) {
              filtersRequests.push(`${item.alias}${type}${item.attribute}`)
            } else if (item.type === 'current_user' && item.alias) {
              filtersRequests.push(`${item.alias}${type}${this.$store.getters['Authorization/userId']}`)
            }
          })
        }
        return filtersRequests
      } */

      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.list
      )

      const filters = builder.buildAsApiQl()
      if (filters.length === 0 && this.isEditor()) {
        return {
          limit: 20
        }
      }
      if (filters.length > 0 && this.isEditor()) {
        return {
          where: {
            and: [...filters]
          },
          limit: 20
        }
      }
      if (filters.length > 0) {
        return {
          where: {
            and: [...filters]
          }
        }
      }

      return {}
      // let filters = {
      //   'where': {
      //     'and': []
      //   }
      // }
      // let isset = false
      // if (this.filters) {
      //   this.filters.forEach((item) => {
      //     let type = item.isXref ? 'equals_any' : 'eq'
      //     let object = {}
      //     if (!item.type || item.type === 'field') {
      //       if (this.getModel()[item.attribute] && item.alias) {
      //         object[type] = {}
      //         object[type][item.alias] = this.getModel()[item.attribute]
      //         filters.where.and.push(object)
      //         isset = true
      //         // filters.push(`${item.alias}=${this.getModel()[item.attribute]}`)
      //       }
      //     } else if (item.type === 'constant' && item.alias) {
      //       object[type] = {}
      //       object[type][item.alias] = item.attribute
      //       // filters.push(`${item.alias}=${item.attribute}`)
      //       filters.where.and.push(object)
      //       isset = true
      //     } else if (item.type === 'current_user' && item.alias) {
      //       object[type] = {}
      //       object[type][item.alias] = this.$store.getters['Authorization/userId']
      //       filters.where.and.push(object)
      //       isset = true
      //     }
      //   })
      // }
      // return isset ? filters : null
    }
  },
  watch: {
    dataFilters: {
      async handler (val) {
        if (this.isFirstInitFilter && this.countFilters === val.where?.and?.length) {
          // console.warn('загрузка начальных фильтров')
          this.saveFilters = val.where?.and || []
          await this.loadData()
          this.isFirstInitFilter = false

          return
        }
        if (!this.isFirstInitFilter && !deepCompare(this.saveFilters, val.where?.and || [])) {
          // console.warn('загрузка watch фильтров')
          this.saveFilters = val.where?.and || []
          await this.loadData()
        }
      }
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  mounted () {
    this.loading = true
    // получить компоненты у которых указаны дефолтное значения
    let componentsWithDefaultValue = []
    componentsWithDefaultValue = this.getComponents().filter(item => {
      if (item.initialType === 'a-checkbox') {
        if (Array.isArray(item.properties?.options)) {
          return item.properties.options.some(item => item.default)
        }
      }
      return item.properties?.defaultValue || item.group === 'registry'
    })
    // console.log({ componentsWithDefaultValue })
    // console.log(this.filters)

    // подсчитать количество фильтров, которые имеют дефолтное значение
    // console.log('количество фильтров, которые имеют дефолтное значение', this.countFilters)
    // !replicationGuid - компонент вне тиражируемых контейнеров
    if (this.filters && !this.replicationGuid) {
      // подсчитать количество фильтров, которые имеют дефолтное значение
      let asyncFilters = componentsWithDefaultValue.filter(component =>
        this.filters.some(filterItem => filterItem.attribute === component.properties.name)
      ).length || 0

      let sumFilters = this.filters.reduce((acc, filterItem) => {
        if (filterItem.type === 'constant') {
          acc['constant'] += 1
        }
        if (filterItem.type === 'current_user') {
          acc['user'] += 1
        }
        if (filterItem.type === 'registry_field') {
          acc['field'] += 1
        }
        return acc
      }, { constant: 0, user: 0, field: 0, asyncFilters })
      // console.log('filters a-list:', sumFilters)
      this.countFilters = asyncFilters + sumFilters.constant + sumFilters.user + sumFilters.field
      // Если фильтров нет, но есть фильтры по умолчанию, значит watch будет и загрузит таблицу с учетом, что такой фильтр не использовался
      if (!this.dataFilters.where?.and?.length && this.countFilters) {
        this.isFirstInitFilter = false
        // console.log('будет watch')
      }
      // Если фильтры есть и они равны фильтрам по умолчанию, значит watch не будет после иницилизаци - грузим данные
      if (this.countFilters === this.dataFilters.where?.and?.length) {
        this.isFirstInitFilter = false
        // console.log('Не будет watch')
        this.loadData()
      } else if (this.countFilters && this.dataFilters.where?.and?.length) {
        // console.log('Неопределенность watch, возможно двойная загрузка')
        setTimeout(() => {
          this.isFirstInitFilter = false
          this.loadData()
        }, 2000)
      }
    }
    // Если фильтров нет, то при иницилизации компонента грузим данные
    if (!this.countFilters && !this.replicationGuid) {
      this.isFirstInitFilter = false
      // console.log('загрузка')

      this.loadData()
    }
    // дальше жесть
    // компонент list с установленными фильтрами находиться в тиражируемом контейнере, у которого тоже есть фильтры.
    // на все воля setTimeout
    if (this.replicationGuid) {
      console.log('загрузка list в тиражируемом контейнере')
      setTimeout(() => {
        this.isFirstInitFilter = false
        this.loadData()
      }, 2000)
    }
    setTimeout(() => {
      this.startAutoRefresh()
    }, 10000)
  },
  methods: {
    async startAutoRefresh () {
      if (this.autoRefresh?.isAllow && this.autoRefresh.seconds >= 5 && !this.isEditor()) {
        let seconds = this.autoRefresh.seconds * 1000
        this.dataWarehouseCount = await this.getCount()
        try {
          while (!this.componentDestroyed) {
            await this.insertValues()
            await new Promise(resolve => setTimeout(resolve, seconds))
          }
        } catch (error) {
          console.error('Ошибка во время обновления:', error)
        }
      }
    },
    async getCount () {
      let res = 0
      const idSource = this.list.extendObjectId || this.list.requestsId
      const lastPayload = this.dataFilters || {}
      const payload = {
        '*': {
          func: 'count'
        },
        ...lastPayload
      }
      try {
        await this.$http
          .post(`${this.$config.api}/datawarehouseservice/${this.list.type}/${idSource}`, payload, { hideNotification: true })
          .then((response) => {
            res = response.data.length ? response.data[0]['count'] : 0
          })
      } catch (error) {
        console.log('ошибка в получении количества записей')
        throw error
      }

      return res
    },
    async insertValues () {
      const lastPayload = this.dataFilters || {}
      const styleRows = this.autoRefresh.css
      let newDataWithFilter = null
      let dataWarehouseCountNew = 0
      const idSource = this.list.extendObjectId || this.list.requestsId
      dataWarehouseCountNew = await this.getCount()
      if (dataWarehouseCountNew <= this.dataWarehouseCount) {
        this.dataWarehouseCount = dataWarehouseCountNew
        return
      }
      let limitNewRows = dataWarehouseCountNew - this.dataWarehouseCount
      if (limitNewRows === 0) {
        return
      }
      // удалить сортировку в запросе
      const { order, ...restObjectWithoutOrder } = lastPayload
      try {
        newDataWithFilter = (await this.$http.post(`${this.$config.api}/datawarehouseservice/${this.list.type}/${idSource}`,
          { ...restObjectWithoutOrder, limit: limitNewRows || 1 }, { hideNotification: true })).data
      } catch (error) {
        console.warm('timer error get data')
        throw new Error('timer error get data')
      }
      this.dataWarehouseCount = dataWarehouseCountNew

      if (!Array.isArray(newDataWithFilter)) {
        return
      }

      if (styleRows) {
        const idsNewRows = newDataWithFilter.map(item => ({ ...item, isNewRows: true }))
        const styleArray = styleRows.split(';').map(pair => pair.replace(/\n/g, '').trim())

        styleArray.forEach(pair => {
          const [key, value] = pair.split(': ')
          this.styleObject[key] = value
        })

        this.data.unshift(...idsNewRows)

        return
      }
      this.data.unshift(...newDataWithFilter)
      console.log('insertValues', newDataWithFilter)
    },
    async loadData () {
      console.log('%c%s', 'color: pink;', 'ЗАГРУЗКА List', `extendObjectId ${this.list.extendObjectId}`, `requestsId: ${this.list.requestsId}`)
      this.saveFilters = this.dataFilters.where?.and || []
      this.loading = true
      if (this.list.type === 'extended_object' && this.list.extendObjectId) {
        this.loading = true
        try {
          let data = await this.$http.post(`${this.$config.api}/datawarehouseservice/extended_object/${this.list.extendObjectId}`, this.dataFilters, { hideNotification: true })
          this.data = data.data
          if (this.name && this.keyField) {
            this.$emit('input', data.data.map((item) => item[this.keyField]))
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
      }
      if (this.list.type === 'requests' && this.list.requestsId) {
        this.data = await this.getRequests(this.list.requestsId)
      }
      if (this.name) {
        this.$set(this.getModel(), `${this.name}_count`, this.data.length)
      }
      this.loading = false
    },
    colorRows (record) {
      if (this.rowClassRules?.length) {
        return this.setColorRows(record)
      }
      return ''
    },
    setColorRows (record) {
      let modelCard = this.getModel()
      return RowClassRulesBuilder.build({
        rules: this.rowClassRules,
        modelCard,
        componentType: 'list',
        dataTable: record
      })
    },
    async getRequests (id) {
      let response = await this.$http.post(`${this.$config.api}/datawarehouseservice/query/${id}`, this.dataFilters, { hideNotification: true })
      return response.data
    },
    async requestOpenCard (openCard, record) {
      let cardId, registryId, recordId
      registryId = record[openCard.requestRegistry]
      recordId = record[openCard.requestRecord]
      cardId = record[openCard.requestCard] || null
      if (!registryId || !recordId) {
        console.warn(`registryId: ${registryId}, recordId: ${recordId}`)
        return
      }
      if (!cardId) {
        cardId = await this.getCardId(registryId, recordId)
      }
      this.openRegistryCard({
        registryId: registryId,
        cardId: cardId.id || cardId,
        cardName: cardId.name || '',
        recordId: recordId
      })
    },

    async onClick (event, columnKey, item, record) {
      // передача item и record сделана для обратной совместимости со старыми действиями
      if (typeof item.action !== 'undefined' && item.action.hasOwnProperty('type') && item.action.type !== null) {
        if (item.action.hasOwnProperty('card') && (item.action?.card?.type === 'update' || item.action?.card?.type === 'read')) {
          let recordId
          let registryId
          let cardId
          try {
            recordId = JSON.parse(record[item.action.card.queryRecord])[0].id
            registryId = JSON.parse(record[item.action.card.queryRegistry])[0].id
            cardId = JSON.parse(record[item.action.card.queryCard])[0].id
          } catch (error) {
            recordId = record[item.action.card.queryRecord]
            registryId = record[item.action.card.queryRegistry]
            cardId = record[item.action.card.queryCard]
          }
          this.getModel()[item.action.card.queryRecord] = recordId
          this.getModel()[item.action.card.queryRegistry] = registryId
          if (cardId) {
            this.getModel()[item.action.card.queryCard] = cardId
          }
        }
        try {
          await ActionExecutor.execute(this, { readonly: item.readonly, pluginName: item.pluginName, action: item.action, event: event }, record)
        } catch (error) {
          console.error('Ошибка действия кнопки', error)
        }
      } else {
        await this.openComponent(item, record)
      }
    },
    async openComponent (openCard, record) {
      this.loading = true
      if (!openCard.clickType || openCard.clickType === 'none') {
        this.loading = false
        return false
      }
      if (openCard.clickType === 'open_card') {
        if (this.list.type === 'requests') {
          await this.requestOpenCard(openCard, record)
          this.loading = false
          return
        }
        await this.openCard(openCard.card, record)
      } else if (openCard.clickType === 'open_dashboard') {
        this.openDashboard(openCard.dashboard, record)
      }
      this.loading = false
    },
    styleHeader (css, item) {
      return `${css} ${item}`
    }
  },
  beforeDestroy () {
    this.componentDestroyed = true
    console.log('beforeDestroy componentDestroyedr', this.componentDestroyed)
  }
}
</script>

<style scoped>
    th {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #7D7D7D;
    }
    td {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;

        text-align: center;

        color: #2C2D35;
    }
    th, td {
        /*opacity: 0.2;*/
        border-bottom: 1px solid rgba(124, 126, 128, 0.2);
        padding: 10px;
        width: 100px;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    thead th {
      position: sticky;
      top: 0;
      /* background: white; */
    }
    .table-fixed-head tbody tr:nth-child(even) {
      background-color: rgb(250 250 250);
    }
    .list_toolbar {
      position: sticky;
      top: 0;
    }
    /* стили для фикисированной шапкки таблицы */
    /* tbody {
      display:block;
      height:50px;
      overflow:auto;
    }
    thead, tbody tr {
      display:table;
      width:100%;
      table-layout:fixed;
    } */
</style>
